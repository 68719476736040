import { PointResults } from '../../../components/PointResults';
import { Button, Card, Col, Form, Input, Radio, Row, Space, Tabs, Typography, Modal, Spin } from 'antd'
import { SearchOutlined, LockOutlined, SettingOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useContext } from 'react'
import Field from '../../../components/Field'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate, NavLink } from 'react-router-dom'
import FetchCalculations from '../../../components/FetchCalculations';
import ResultCard from '../../../components/ResultCard'
import { CalculationStatusModal } from '../../../components/CalculationStatusModal'
import { ReactComponent as Bars } from '../../../assets/Bars.svg'
import { ReactComponent as Wedges } from '../../../assets/Wedges.svg'
import { ConfirmCalculateModal } from '../../../components/ConfirmCalculateModal'
import Loader from '../../../assets/Loader'
import './Calcs.css'
import SettingsModal from './SettingsModal';
import { FooterHandler } from '../../../components/Footer/Footer';
import WavelengthField from './MPE_AEL/QuickFields/WavelengthField';
import { EnvironmentContext } from '../../../routes/EnvironmentContext';
import ErrorPage from '../../ErrorPages/ErrorPage';

const textCenter = { display: 'flex', justifyContent: 'center' }

const defaultValues = {
  lookupTable: {
    time: {
      unit: false
    },
    sourceType: 'point',
    source: {
      parameter: 'sizeDistance',
      distance: {
        unit: false
      },
      sourceSize: {
        unit: 'm'
      },
      angularSubtense: {
        unit: 'm'
      }
    }
  }
}

const LookupTable = () => {
  const { isLocal, myUser, setMyUser, selectedStandard } = useContext(EnvironmentContext)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [results, setResults] = useState(null)
  const [isFullScreen, setisFullScreen] = useState(false)
  const [numberOfCalcs2, setNumberOfCalcs2] = useState(null)
  const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial } = FetchCalculations(isLocal, myUser, numberOfCalcs2)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [spinning, setSpinning] = useState(false);
  const [openBuy, setOpenBuy] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  {/*Lets the user calculate if numberofcalculations !== 0 */ }
  const checkStatus = () => {
    {/*Prompts user to buy more calculations if numberofcalculations === 0 */ }
    if (isDisabled === true && isSubscribed === false && isFreeTrial === null) {

      setOpenBuy(true)
    }
    else if (isDisabled === false || isSubscribed === true || isFreeTrial === true) {
      const { hide_confirmation } = JSON.parse(Cookies.get('RTLSS_SETTINGS'))
      if (hide_confirmation) {
        submitForm()
      } else {
        {/*Prompts the user to proceed with the calculation */ }
        showPopconfirm(true)
      }
    }
  }

  const handleCancelCheckStatus = () => {
    {/**Close calculation status modal */ }

    setOpenBuy(false)
  };

  useEffect(() => {
    if (numberOfCalcs !== null) {
      setSpinning(false);
    } else {
      setSpinning(true);
    }
  }, [numberOfCalcs]);

  const showPopconfirm = () => {
    setIsConfirmOpen(true);
  };
  const handleCancelPopConfirm = () => {

    setIsConfirmOpen(false);
  };


  const toggleFullscreen = () => {
    setisFullScreen((prevFullScreen) => !prevFullScreen)
  }


  const onValuesChange = () => {
    setResults(null)
  }

  const submitForm = () => {
    setIsConfirmOpen(false);
    setIsSubmitting(true)
    form.setFieldValue('laserOperation', 'lookupTable')
    const data = form.getFieldsValue()
    let url = () => {
      if (isLocal === true) {
        return 'http://localhost:8000/calculate'
      } else {
        return 'api/calculate'
      }
    }

    Axios({
      url: url(),
      method: 'post',
      withCredentials: true,
      headers: { Authorization: `Bearer ${Cookies.get('RTLSS_TOKEN')}` },
      data: { data, numberOfCalcs, isFreeTrial, isSubscribed }
    }).then((res => {
      setResults(res.data.calcResult)
      setNumberOfCalcs2(res.data.newNumberOfCalculations)
      setIsSubmitting(false)
    })).catch((err) => {
      navigate('/logout')
    })
  }
  return (
    !['iec'].includes(selectedStandard) ? (
      <ErrorPage />
    ) : (
      <>
        <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", left: "50%", transform: "translate(-41%, -5%)" }} />}>
          <div class="calcs">
            <Spin spinning={isSubmitting} indicator={<Loader />} style={{ zIndex: 10000, fontSize: "500px" }}>
              {numberOfCalcs !== null ? (
                <>
                  {/* <Row justify='center'>
            <h2 style={{textAlign: "center"}}>Lookup Table</h2>
          </Row> */}

                  <Row justify={"center"}>
                    <Col lg={results === null ? 24 : 12} sm={24} style={{
                      padding: '15px 20px',
                      display: (() => {
                        if (isFullScreen) {
                          return "none"
                        }
                        else {
                          return null
                        }
                      })()
                    }}>
                      <Row justify="center">
                        <Col lg={results === null ? 10 : 18} md={24}>
                          <Card
                            title={<h4>Lookup Table</h4>}
                            style={{ width: '100%', display: 'block', marginTop: "60px" }}
                            extra={
                              <Button
                                type='text'
                                icon={<SettingOutlined />}
                                onClick={() => {
                                  setShowSettingsModal(true)
                                }}
                              />
                            }
                          >
                            <Form
                              onValuesChange={onValuesChange}
                              form={form}
                              initialValues={defaultValues}
                              onFinish={checkStatus}
                              labelCol={{ span: 8 }}>

                              <Form.Item
                                name={['lookupTable', 'sourceType']}
                                label='Source'>
                                <Radio.Group optionType='default'>
                                  <Radio.Button value='point'>Point Source</Radio.Button>
                                  <Radio.Button value='extended'>Extended Source</Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              <Form.Item noStyle shouldUpdate={(curr, prev) => curr.lookupTable.sourceType !== prev.lookupTable.sourceType}>
                                {({ getFieldValue }) => {
                                  let sourceType = getFieldValue(['lookupTable', 'sourceType'])
                                  return (
                                    sourceType === 'point' ? (null) : (
                                      sourceType === 'extended' ? (
                                        <Card
                                          style={{ margin: '24px 0 24px' }}
                                          title='Extended Source Setting'
                                          size='small'>

                                          {/* <Form.Item
                                    name={['lookupTable', 'source', 'parameter']}
                                    label='Source'>
                                    <Radio.Group optionType='sizeDistance'>
                                      <Space direction='horizontal'>
                                        <Radio.Button value='sizeDistance'>Size & Distance</Radio.Button>
                                        <Radio.Button value='angularSubtense'>Angular Subtense</Radio.Button>
                                      </Space>
                                    </Radio.Group>
                                  </Form.Item> */}
                                          <Form.Item noStyle name={['lookupTable', 'source', 'parameter']} />
                                          <Field name={['lookupTable', 'source', 'sourceSize']}
                                            label='Apparent Source Size'
                                            unit='m'
                                            denoms={['u', 'm', 'c', false]}
                                            placeholder='Enter apparent source size'
                                            toolTipText={`The diameter of the apparent source of the laser beam, meaured across its axis.`}
                                          />

                                          <Field name={['lookupTable', 'source', 'distance']}
                                            label='Viewing Distance'
                                            unit='m'
                                            denoms={['m', 'c', false, 'k']}
                                            placeholder='Enter viewing distance'
                                            toolTipText={'The distance from the laser source at which exposure is expected to take place in.'}
                                          />
                                        </Card>
                                      ) : null
                                    ))

                                }}
                              </Form.Item>

                              {/* This is a hidden item. It's here to hold the look up table as an initial value. */}
                              <Form.Item hidden name='laserOperation'>
                                <Input />
                              </Form.Item>

                              <WavelengthField name={['lookupTable', 'wavelength']} />

                              {/* <Field
                              name={['lookupTable', 'wavelength']}
                              label='Wavelength'
                              placeholder={`Enter laser's wavelength`}
                              min='180'
                              unit='nm' /> */}
                              <Field
                                name={['lookupTable', 'time']}
                                label='Exposure time'
                                placeholder={`Enter time exposure`}
                                min='0.001'
                                unit='s'
                                denoms={['f', 'p', 'n', 'u', 'm', false]} />

                              <div class="hover-underline-animation2" style={{ width: '100%' }} >
                                <Button
                                  htmlType='submit'
                                  block
                                  type='primary'
                                  style={{
                                    margin: '24px 0 0',
                                    background: '#1d1f8c',

                                  }}
                                >

                                  Lookup</Button>
                              </div>
                            </Form>
                            {isSubscribed === false ? (
                              <>
                                <Row justify={'end'} style={{ marginTop: "1%" }}>
                                  <Typography.Text type='secondary' style={{ fontSize: "20px" }}>
                                    {isFreeTrial ? <i>Free Trial</i> : <i>Remaining Calculations: <strong style={{ color: "black" }}>{numberOfCalcs}</strong></i>}
                                  </Typography.Text>
                                </Row>
                                {numberOfCalcs < 6 && !isFreeTrial ? (
                                  <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "15px" }}>Running low on calculations? Get more calculations <NavLink to={`/home?defKey=${"4"}&handleClick=${"billing"}`}>here!</NavLink></Typography.Text></Row>
                                ) : null}
                              </>
                            ) :
                              <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "20px" }}><i>Unlimited Calculations</i></Typography.Text></Row>}
                          </Card>
                        </Col>
                      </Row>
                    </Col>

                    {/* Results */}
                    {results !== null ? (
                      <Col lg={12} sm={24} style={{ padding: "0px 20px", width: "100%", marginTop: "60px", marginBottom: "100px" }}>
                        <Row justify={"center"}>

                          {results.calcType === 'extended' ? (<PointResults textCenter={textCenter} results={results} toggleFullscreen={toggleFullscreen} isFullScreen={isFullScreen} />) : (
                            <PointResults textCenter={textCenter} results={results} toggleFullscreen={toggleFullscreen} isFullScreen={isFullScreen} />
                          )}

                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : null}
            </Spin>
          </div>
        </Spin>

        {/*Confirm calculate */}
        <ConfirmCalculateModal isConfirmOpen={isConfirmOpen} handleCancelPopConfirm={handleCancelPopConfirm} submitForm={submitForm}></ConfirmCalculateModal>

        <SettingsModal showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />

        <CalculationStatusModal openBuy={openBuy} handleCancelCheckStatus={handleCancelCheckStatus}></CalculationStatusModal>
      </>
    )
  )
}

export default LookupTable