/*
================================================================================
FILE        : Login.jsx
AUTHOR      : Kathryn Marie P. Sigaya
DESCRIPTION : JSX file that shows the login page.
COPYRIGHT   : 26 September 2023
REVISION HISTORY
Date: 			     By: 		 Description:
26 September 2023    Sigaya      Creation of file, initial layout using Ant Design framework
18 October 2023      Sigaya      Added mini icon, changed font family to Helvetica Neue, changed
                                 colors to official colors
================================================================================
*/
import React, { useContext, useState } from "react";
import Axios from 'axios'
import Cookies from "js-cookie";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Flex,
} from 'antd';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate, NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/Logo/logosidetext.png'
import { FooterHandler } from "../components/Footer/Footer";
import { EnvironmentContext } from "../routes/EnvironmentContext";

// const {Title} = Typography;

function Login() {
  const { setMyUser, myUser, isLocal } = useContext(EnvironmentContext)
  const location = useLocation()
  const message = location?.state?.message || null
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)
  const navigate = useNavigate()
  const [form] = Form.useForm();

  const handleCloseFlash = () => {
    setFlashMessage(null)
  }

  const onFinish = (values) => {
    const { username, password, remember } = form.getFieldsValue()
    setIsSubmitting(true)
    Axios({
      method: 'post',
      data: { username: username, password: password, remember: remember },
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/jwt-login'
        } else {
          return 'api/jwt-login'
        }
      })(),
      withCredentials: true,
    })
      .then((response) => {
        fetch('https://ipinfo.io/json?token=7a0cb72fba6b42')
          .then(response => response.json())
          .catch(() => ({ country: "GB", timezone: "Europe" }))
          .then(data => {
            Cookies.set('RTLSS_Loc', JSON.stringify(data), { expires: 1 })
            console.log(`Hello there user`, response.data)
            setMyUser({ ...response.data[1] })
            // console.log(`token successfully appended.`)
            setFlashMessage({ type: 'success', message: 'Login successful!' })
            navigate('/home')
            // setDisableNav(false)
          })

      })
      .catch((err) => {
        setIsSubmitting(false)
        console.error(err)
        setFlashMessage({ type: 'error', message: err.response?.data.error || 'Error' })
      })
  };

  const onFinishFailed = (errorInfo) => {

  };
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1d1f8c',

          },
          components: {
            Layout: {
              bodyBg: '#efefef',
              footerBg: '#1d1f8c'
            },
            Input: {
              errorActiveShadow: '#d82424'
            }
          }
        }
        }>

        <Flex justify='center' align='center' style={{ width: "100%", minHeight: "100vh" }}>
          <Card
            // title={<Typography.Text style={{ fontWeight: '500' }}>Log In to the System</Typography.Text>}
            style={{
              width: 500,
              height: "auto",
              fontWeight: '500',
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            }}
            cover={
              <Flex justify='center' align='center' style={{ marginBottom: "30px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                <img
                  rel="preload"
                  as="image"
                  alt="Logo"
                  justify='center' align='center'
                  src={logo}
                  style={{ width: "100%", maxWidth:"400px", height:"auto",justifySelf: "center"}}
                />
              </Flex>
            }
            bordered={false}>
            <Form
              name="Log In"
              className="login-form"
              initialValues={{
                remember: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username.'
                  }
                ]}
              >
                <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username" />
              </Form.Item>
              {/* Password  */}
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password.',
                  },
                ]}
              >
                {/*Input.Password has the visibility toggle*/}
                <Input.Password
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                />
              </Form.Item>
              <Row justify={'space-between'}>
                <Form.Item
                  name="remember"
                  noStyle
                  valuePropName="checked"
                >
                  <Checkbox style={{ fontSize: '16px' }}>Remember me</Checkbox>
                </Form.Item>
                <NavLink to='/forgotPassword'>
                  Forgot Password
                </NavLink>
              </Row>
              <Form.Item>
                <Button loading={isSubmitting} size={'large'} type={'primary'} htmlType="submit"
                  className="login-form-button" block>
                  Log In
                </Button>
              </Form.Item>
              <Row justify={"center"}>
                <Link to="/register"><h6>Create a new account</h6></Link>
                <Divider type="vertical" style={{ height: "25px" }} />
                <Link to="/confirm"><h6>Activate Account</h6></Link>

              </Row>
              <Typography.Text type="secondary" fontFamily='Segoe UI' style={{ fontWeight: "lighter", fontSize: "12px" }}>By logging in, you accept our use of essential cookies as described in our <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{ textDecoration: "underline" }}>Privacy Policy</a></Typography.Text>

              {flashMessage != null ? (
                <Alert
                  description={flashMessage.message}
                  type={flashMessage.type}
                  closable
                  onClose={handleCloseFlash}
                />
              ) : (null)}

              {
                message ? (
                  <Alert
                    description={message}
                    type={'error'}
                    closable
                    onClose={handleCloseFlash}
                  />
                ) : null
              }
            </Form>
          </Card>
          {/* </Content> */}
        </Flex>

        {/*Footer Section*/}
        <FooterHandler />
      </ConfigProvider>
    </>
  )
}

export default Login;
