import React, { useContext, useState } from 'react'
import { Card, Col, Form, Spin, Radio, Row, Typography, Button, Tooltip, Badge, Flex, Space } from 'antd'
import { ReactComponent as Bars } from '../../../../../assets/Bars.svg'
import Loader from '../../../../../assets/Loader'
import '../../../../../components/nav_bar.css'
import Field from '../../../../../components/Field'
import { EnvironmentContext } from '../../../../../routes/EnvironmentContext'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import { FreeContext } from '../../../../../routes/FreeHandler/FreeContext'
import ErrorPage from '../../../../ErrorPages/ErrorPage'
import { ResultCW } from '../../Results/MPE_AEL_Results/ResultCW'
const { Title, Text } = Typography
const CWCalculatorFree = () => {
    const { standard } = useParams()
    const allowedParams = ['iec', 'ansi']
    const { isLocal, selectedStandard, messageApi } = useContext(EnvironmentContext)
    const { isFreeButtonLoading, verifyAndCalculate, freeModeText, pointAndExtended, freeResult: result } = useContext(FreeContext)
    const isFullScreen = false
    const [sample] = Form.useForm()
    const [units, setunits] = useState("watts")

    const onUnitChange = (e) => {
        setunits(e.target.value)
    }

    // console.log(`paramino: `, x)
    if (allowedParams.includes(standard)) {
        return (
            <>
                <Spin
                    spinning={false}
                    size='large'
                    indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", right: "50%", top: "60%", transform: "translate(-41%, -50%)" }} />}
                >
                    <div class='calcs'>
                        <Spin spinning={false} indicator={<Loader />} style={{ zIndex: 10000, fontSize: "500px" }}>
                            <Row justify='center'>

                                <Col
                                    lg={!result ? 24 : 12}
                                    md={24}
                                    style={{
                                        marginTop: '60px',
                                        padding: '15px 20px',
                                        // display: isFullScreen ? "none" : null
                                        display: null
                                    }}
                                >
                                    <Row justify={'center'}>
                                        <Col lg={!result ? 10 : 18} md={24}>
                                            <Badge.Ribbon text='Free' color='green'>
                                                <Card
                                                    size='small'
                                                    title={
                                                        <Title level={4}>Free Point and Extended Calculator</Title>
                                                    }
                                                    stlyle={{
                                                        width: '100%',
                                                        display: 'block ',
                                                        marginTop: '60px',
                                                        boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.3)"
                                                    }}
                                                >
                                                    <Flex justify='center' style={{ margin: '0 0 24px' }}>
                                                        <Text type='secondary'>
                                                            Try our free calculation tool for {selectedStandard === 'iec' ? 'IEC-60825' : 'ANSI-Z136'} with pre-set values. Full customization avilable in the <NavLink to='/'>paid version</NavLink>.
                                                        </Text>
                                                    </Flex>

                                                    {/* <Row justify='center' align='middle' style={{ marginBottom: "30px" }}>
                                            <h1 style={{ margin: '30px 0px 0 0px', color: '#1d1f8c', textAlign: "center", fontSize: '2rem', }}>
                                                Try our free calculation tool with pre-set values. Full customization available in the paid version.
                                            </h1>
                                        </Row> */}
                                                    <Form
                                                        onFinish={() => {
                                                            // console.log(`yehey`, sample.getFieldsValue())
                                                            verifyAndCalculate(sample.getFieldsValue(), selectedStandard)
                                                        }}
                                                        form={sample}
                                                        name='sample-point-and-extended'
                                                        labelCol={{ span: 8 }}
                                                        initialValues={pointAndExtended}

                                                    >
                                                        {/* Laser Operation */}
                                                        <Form.Item
                                                            name='laserOperation'
                                                            label='Laser Operation'
                                                        >
                                                            <Radio.Group optionType='default' disabled>
                                                                <Radio.Button value='cw'>CW</Radio.Button>
                                                                <Radio.Button value='repetitivelyPulsed'>Repetitively Pulsed</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        {/* Source Type */}
                                                        <Form.Item
                                                            name='sourceType'
                                                            label='Source Type'
                                                        >
                                                            <Radio.Group
                                                                optionType='default' disabled>
                                                                <Radio.Button value='point'>Point</Radio.Button>
                                                                <Radio.Button value='extended'>Extended</Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        {/* Extended Settings */}
                                                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                                                            {({ getFieldValue }) => {
                                                                const sourceType = getFieldValue(['sourceType'])
                                                                if (sourceType === 'extended') {
                                                                    return (
                                                                        <Card
                                                                            style={{ margin: '0 0 24px' }}
                                                                            title='Extended Settings'
                                                                            size='small'>
                                                                            <Tooltip
                                                                                title={freeModeText}
                                                                                mouseEnterDelay={0.5}
                                                                                placement='left'
                                                                                destroyTooltipOnHide
                                                                            >
                                                                                <Form.Item
                                                                                    label='Select Source Input'
                                                                                    name={['extendedSettings', 'sourceInput']}
                                                                                >

                                                                                    <Radio.Group disabled optionType='default'>
                                                                                        <Radio.Button value='apparentSourceSize'>Apparent Source Size</Radio.Button>
                                                                                        <Radio.Button disabled value='Angular Subtense'>Angular Subtense</Radio.Button>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Tooltip>
                                                                            <Field

                                                                                toolTipText={freeModeText}
                                                                                disabled
                                                                                name={['extendedSettings', 'apparentSourceSize']}
                                                                                label='Apparent Source Size'
                                                                                unit='m'
                                                                                placeholder={`Enter apparent source size`}
                                                                                denoms={['u', 'm', 'c']}
                                                                            />
                                                                        </Card>
                                                                    )
                                                                }
                                                                return null
                                                            }}
                                                        </Form.Item>

                                                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.laserOperation !== curr.laserOperation}>
                                                            {({ getFieldValue }) => {
                                                                const sourceType = getFieldValue(['laserOperation'])
                                                                return sourceType === 'cw' ? (
                                                                    <>
                                                                        {/* CW Settings */}
                                                                        <Card
                                                                            size='small'
                                                                            title='CW Settings'
                                                                        >
                                                                            {/* Wavelength */}
                                                                            <Field
                                                                                disabled
                                                                                toolTipText={freeModeText}
                                                                                label='Wavelength'
                                                                                name={['cw', 'wavelength']}
                                                                                unit='m'
                                                                                denoms={['u', 'n']}
                                                                            />
                                                                            {/* Exposure Duration */}
                                                                            <Field
                                                                                unit='s'
                                                                                disabled
                                                                                toolTipText={freeModeText}
                                                                                label='Exposure Duration'
                                                                                name={['cw', 'exposureDuration']}
                                                                                denoms={['u', 'm', false]}
                                                                            />
                                                                            <Field
                                                                                unit='W'
                                                                                disabled
                                                                                toolTipText={freeModeText}
                                                                                label='Laser Power'
                                                                                name={['cw', 'power']}
                                                                                denoms={['u', 'm', false, 'k']}
                                                                            />
                                                                            <Form.Item
                                                                                noStyle
                                                                                shouldUpdate={(curr, prev) => curr.sourceType !== prev.sourceType}>
                                                                                {
                                                                                    ({ getFieldValue }) => {
                                                                                        const sourceType = getFieldValue(['sourceType'])
                                                                                        return sourceType === 'point' ? (
                                                                                            <Field
                                                                                                disabled
                                                                                                unit='m'
                                                                                                toolTipText={freeModeText}
                                                                                                label='Beam Diameter'
                                                                                                name={['cw', 'beamDiameter']}
                                                                                                denoms={['u', 'm', false]}
                                                                                            />
                                                                                        ) : null
                                                                                    }
                                                                                }
                                                                            </Form.Item>
                                                                            <Field
                                                                                disabled
                                                                                unit='rad'
                                                                                toolTipText={freeModeText}
                                                                                label='Beam Divergence'
                                                                                name={['cw', 'beamDivergence']}
                                                                                denoms={['u', 'm', false]}
                                                                            />
                                                                            <Field
                                                                                unit='m'
                                                                                disabled
                                                                                toolTipText={freeModeText}
                                                                                label='Distance to target'
                                                                                name={['cw', 'distance']}
                                                                                denoms={['u', 'm', false]}
                                                                            />
                                                                        </Card>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Card
                                                                            size='small'
                                                                            title='Repetitively Pulsed Settings'
                                                                        >
                                                                            <Card
                                                                                size='small'
                                                                                title='Laser Settings'
                                                                            >
                                                                                <Field
                                                                                    disabled
                                                                                    label='Wavelength'
                                                                                    name={['repetitivelyPulsed', 'wavelength']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['n', 'u']}
                                                                                    unit='m'
                                                                                />
                                                                                <Field
                                                                                    disabled
                                                                                    label='Exposure Duration'
                                                                                    name={['repetitivelyPulsed', 'time']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['n', 'u', false]}
                                                                                    unit='s'
                                                                                />

                                                                                <Form.Item noStyle shouldUpdate={(curr, prev) => curr.sourceType !== prev.sourceType}>
                                                                                    {({ getFieldValue }) => {
                                                                                        const sourceType = getFieldValue('sourceType')
                                                                                        return (
                                                                                            sourceType === 'point' ? (
                                                                                                <Field
                                                                                                    disabled
                                                                                                    label='Beam Diameter'
                                                                                                    name={['repetitivelyPulsed', 'beamDiameter']}
                                                                                                    toolTipText={freeModeText}
                                                                                                    denoms={['n', 'u']}
                                                                                                    unit='m'
                                                                                                />
                                                                                            ) : null
                                                                                        )
                                                                                    }}
                                                                                </Form.Item>
                                                                                <Field
                                                                                    disabled
                                                                                    label='Beam Divergence'
                                                                                    name={['repetitivelyPulsed', 'beamDivergence']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['m', false]}
                                                                                    unit='rad'
                                                                                />
                                                                                <Field
                                                                                    disabled
                                                                                    name={['repetitivelyPulsed', 'distance']}
                                                                                    label='Distance to target'
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['m', false, 'k']}
                                                                                    unit='m'
                                                                                />
                                                                            </Card>

                                                                            <Card
                                                                                style={{ margin: '24px 0 0' }}
                                                                                size='small'
                                                                                title='Laser Output'
                                                                            >
                                                                                <Form.Item
                                                                                    label='Laser Output Unit'
                                                                                    name={['repetitivelyPulsed', 'outputUnit']}
                                                                                >
                                                                                    <Radio.Group
                                                                                        disabled
                                                                                        optionType='default'
                                                                                    >
                                                                                        <Radio.Button value='energy'>Energy</Radio.Button>
                                                                                        <Radio.Button value='peakPower'>Peak Power</Radio.Button>
                                                                                        <Radio.Button value='averagePower'>Average Power</Radio.Button>
                                                                                    </Radio.Group>
                                                                                </Form.Item>

                                                                                <Field
                                                                                    disabled
                                                                                    label='Average Power'
                                                                                    name={['repetitivelyPulsed', 'averagePower']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['u', 'm', false, 'k']}
                                                                                    unit='W'
                                                                                />
                                                                                <Field
                                                                                    disabled
                                                                                    label='Pulse Duration'
                                                                                    name={['repetitivelyPulsed', 'pulseDuration']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['n', 'u', 'm', false]}
                                                                                    unit='s'
                                                                                />
                                                                                <Field
                                                                                    disabled
                                                                                    label='Pulse Frequency'
                                                                                    name={['repetitivelyPulsed', 'pulseFrequency']}
                                                                                    toolTipText={freeModeText}
                                                                                    denoms={['u', 'm', false, 'k', 'M']}
                                                                                    unit='Hz'
                                                                                />
                                                                            </Card>

                                                                        </Card>
                                                                    </>
                                                                )
                                                            }}
                                                        </Form.Item>





                                                        <Button
                                                            type='primary'
                                                            block
                                                            loading={isFreeButtonLoading}
                                                            htmlType='submit'
                                                            style={{ margin: '24px 0 0' }}
                                                        >
                                                            Try out this Calculation
                                                        </Button>
                                                        {/* Debug Mode - Shows only on local environment */}
                                                        {isLocal && (
                                                            <Form.Item noStyle shouldUpdate>
                                                                {() => (
                                                                    <pre>
                                                                        {JSON.stringify(sample.getFieldsValue(), null, 2)}
                                                                    </pre>
                                                                )}
                                                            </Form.Item>
                                                        )}
                                                    </Form>


                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                    </Row>
                                </Col>

                                {result && (
                                    <Col lg={12} sm={24} style={{ padding: '15px 20px', marginTop: '60px' }}>
                                        <Row justify={'center'}>
                                            <Card
                                                title="Free Calculation Results"
                                                style={{ width: '90vw', boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.3)" }}
                                                extra={[
                                                    <>
                                                        <Space>
                                                            <Radio.Group onChange={onUnitChange} value={units} buttonStyle="solid">
                                                                <Radio.Button value="joules">Joules</Radio.Button>
                                                                <Radio.Button value="watts">Watts</Radio.Button>
                                                            </Radio.Group>
                                                        </Space>
                                                    </>
                                                ]}
                                            >
                                                {
                                                    result.calcResult.laserOperation === 'cw' ? (
                                                        <ResultCW
                                                            result={result.calcResult}
                                                            textCenter={{ display: 'flex', justifyContent: 'center' }}
                                                            units={units}
                                                            data={result.data}
                                                        />
                                                    ) : null
                                                }


                                            </Card>
                                        </Row>
                                    </Col>
                                )}

                            </Row>
                        </Spin>
                    </div >
                </Spin >






            </>




        )
    } else {
        return <ErrorPage />
    }
}

export default CWCalculatorFree