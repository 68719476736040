import { Card, Form, Radio } from 'antd'
import React from 'react'
import Field from '../../../../../components/Field'
import WavelengthField from '../../MPE_AEL/QuickFields/WavelengthField'

const CWCalculator_ANSI = () => {
    return (
        <>
            <Form.Item noStyle></Form.Item>
            <Card style={{ margin: '24px 0 0' }} size='small' title='CW Settings'>
                <WavelengthField name={['cw', 'wavelength']} />


                {/* Exposure Duration */}
                <Field
                    min={0.000001}
                    isCW
                    tabIndex={2}
                    name={['cw', 'time']}
                    label='Exposure Duration'
                    unit='s'
                    denoms={[false, 'm', 'u', 'p', 'n', 'f']}
                    placeholder={`Enter emission duration`}
                    toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
                />


                {/* Laser Power */}
                <Field
                    tabIndex={3}
                    min={0.000001}
                    name={['cw', 'power',]}
                    label='Laser Power'
                    unit='W'
                    denoms={['u', 'm', false, 'k', 'M']}
                    placeholder={`Enter laser's output power`}
                    toolTipText={`The laser's rated power expressed in watts.`}
                />


                {/* Beam Diameter */}
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
                    {({ getFieldValue }) => {
                        let sourceType = getFieldValue(['sourceType'])
                        return (
                            sourceType === 'point' ? (
                                <>

                                    <Field
                                        min={0.000001}
                                        tabIndex={4}
                                        name={['cw', 'beamDiameter']}
                                        label='Beam Diameter'
                                        unit='m'
                                        denoms={["m", "u"]}
                                        placeholder={`Enter laser's beam diameter`}
                                        toolTipText={`The diameter of the source of the laser beam.`}
                                    />
                                </>
                            ) : null
                        )
                    }}
                </Form.Item>


                {/* Beam Geometry*/}
                <Form.Item
                    label='Beam Geometry'
                    name={['cw', 'beamGeometry']}>
                    <Radio.Group optionType='default'>
                        <Radio.Button value='beamDivergence'>Beam Divergence</Radio.Button>
                        <Radio.Button value='outputBeamDiameter'>Output Beam Diameter</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(curr, prev) => curr.cw.beamGeometry !== prev.cw.beamGeometry}>
                    {({ getFieldValue }) => {
                        let beamGeometry = getFieldValue(['cw', 'beamGeometry'])
                        console.log(`Hello world`)
                        if (beamGeometry === 'beamDivergence') {
                            return (
                                <>
                                    {/* Beam Divergence */}
                                    <Field
                                        tabIndex={5}
                                        name={['cw', 'beamDivergence']}
                                        label='Beam Divergence'
                                        unit='rad'
                                        denoms={['m', false, 'degree']}
                                        placeholder={`Enter laser's beam divergence`}
                                        toolTipText={`The far field plane angle of the cone defined by the beam diameter.`}
                                    />
                                </>
                            )
                        }

                        if (beamGeometry === 'outputBeamDiameter') {
                            return (
                                <>
                                    {/* Output Beam Diameter */}
                                    <Field
                                        tabIndex={5}
                                        name={['cw', 'outputBeamDiameter']}
                                        label='Output Beam Diameter'
                                        unit='m'
                                        denoms={['m', 'c', false]}
                                        placeholder={`Enter laser's beam diameter at target.`}
                                        toolTipText={`The far field plane angle of the cone defined by the beam diameter.`}
                                    />
                                </>
                            )
                        }

                        throw new Error("Beam Geometry was undefined.")
                    }}
                </Form.Item>



                {/* Distance to target */}
                <Field
                    tabIndex={6}
                    name={['cw', 'distance']}
                    label='Distance to target'
                    unit='m'
                    denoms={['k', false, 'c', 'm', 'u', 'n', 'p', 'f']}
                    placeholder={`Enter distance from the laser`}
                    toolTipText={`The distance between the source of the laser beam and the target.`}
                />
            </Card>
        </>
    )
}

export default CWCalculator_ANSI