import React, { createContext, useCallback, useContext, useState } from 'react'
import { pointAndExtended } from './freeCalcValues'
import { NavLink, useNavigate } from 'react-router-dom'
import { EnvironmentContext } from '../EnvironmentContext'
import axios from 'axios'
import Cookies from 'js-cookie'
import { message } from 'antd'

const FreeContext = createContext()

const FreeProvider = ({ children }) => {
  // const [data, setData] = useState(null)
  const [messageApi, contextHolder] = message.useMessage()
  const [freeResult, setFreeResult] = useState(null)
  const { isLocal } = useContext(EnvironmentContext)
  const navigate = useNavigate()
  const [isFreeButtonLoading, setIsFreeButtonLoading] = useState(false)
  const freeModeText = (
    <>
      You are not able to change values in free mode!
      <br />
      <NavLink to='/'>Upgrade your account now!</NavLink>
    </>
  )
  const verifyAndCalculate = (data, selectedStandard) => {
    setIsFreeButtonLoading(true)
    {/*Prompts user to buy more calculations if numberofcalculations === 0 */ }
    axios({
      method: 'post',
      url: isLocal ? 'http://localhost:8000/check-refresh' : 'api/check-refresh',
      data: Cookies.get('RTLSS_TOKEN'),
      timeout: 5000,
      timeoutErrorMessage: 'Connection timed out when checking for refresh token.',
      withCredentials: true
    })
      .then(() => {
        calculateFree(data, selectedStandard)
      })
      .catch(() => {
        navigate('/logout', { state: { message: 'Token expired. Please login again.' } })
      })

  }
  const calculateFree = (data, selectedStandard) => {
    // if (!data) throw new Error('Data is null when using free calculate.')
    axios({
      method: 'post',
      url: isLocal ? 'http://localhost:8000/calculate-free' : 'api/calculate-free',
      withCredentials: true,
      data: { data, selectedStandard }
    })
      .then((res) => {
        setFreeResult(res.data)
        console.log(`Front-end recieved back end confirmation trial.`, res.data.calcResult)
      })
      .catch((err) => {
        // console.error('hoyyyyyy', err)
        // messageApi.error("Rate limit exceeded. Try again in a while.")
      })
      .finally(() => {
        setIsFreeButtonLoading(false)
      })
  }


  return (
    <FreeContext.Provider
      value={{ isFreeButtonLoading, verifyAndCalculate, freeModeText, pointAndExtended, freeResult }}
    >
      {contextHolder}
      {children}
    </FreeContext.Provider>
  )
}

export { FreeContext, FreeProvider }