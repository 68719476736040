import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nav_bar.css';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import RTLLSS from '../assets/Logo/logosidetext.png'
import DiffuseReflection2 from '../assets/MenuCalculators/Diffuse Reflection P.png'
import PointnadExtendedP from '../assets/MenuCalculators/Laser P.png'
import FiberOpticP from '../assets/MenuCalculators/Fibre Optics P.png'
import LookupTableP from '../assets/MenuCalculators/LookUp Table P.png'
import { EnvironmentContext } from '../routes/EnvironmentContext';


function Nav_bar() {
  // const { myUser, setMyUser, isLocal } = useContext(EnvironmentContext)
  const { selectedStandard } = useContext(EnvironmentContext)
  const [expand, setExpand] = useState(false)
  const location = useLocation();

  const handleExpand = () => {
    setExpand((prevExpand) => !prevExpand)
  }

  useEffect(() => {
    setExpand(false)
  }, [location.pathname])

  useEffect(() => {
    window.addEventListener("click", function (event) {
      if (event.target.id !== "clicker" && event.target.id !== "test" && event.target.id !== "nav") {
        setExpand(false);
      }
    })
  }, [])

  return (
    <>
      <>
        <Navbar
          collapseOnSelect
          expand="md"
          className={expand && location.pathname !== "/home" && location.pathname !== "/" ? "bg-body expandNav"
            : !expand && location.pathname !== "/home" && location.pathname !== "/" ? "bg-body collapseNav"
              : location.pathname === "/home" ? "bg-body"
                : location.pathname === "/" ? "bg-body"
                  : "bg-body"}
          class="row "
          id="nav"
          fixed="top"
          expanded="true"
          style={{ justifyContent: "center" }}
        // style={{height: expand && location.pathname !=="/home" && location.pathname !=="/" ? "500px" : location.pathname === "/home" ? null : location.pathname === "/" ? null : null }}
        >
          <div class="cover" id="test"> </div>

          <Button
            className={expand ? "animateButton" : "animateButton2"}
            id="clicker"
            shape='circle'
            onClick={() => handleExpand()}
            style={{
              zIndex: 10,
              border: "none",
              backgroundColor: "whitesmoke",
              color: "#1d1f8c",
              width: "40px",
              height: "40px",
              position: "absolute",
              left: "50%",
              right: "50%",
              transform: 'translateX(-50%)',
              top: 80,
              display: location.pathname === `/mpe-ael-calc/${selectedStandard}` || location.pathname === `/fiber-optic/${selectedStandard}` || location.pathname === `/diffuse-reflective/${selectedStandard}` || location.pathname === `/lookup-table/${selectedStandard}` ? null : 'none',
            }}
          >
            <span style={{ pointerEvents: 'none' }}>
              {expand ? <UpOutlined style={{ pointerEvents: 'none' }} /> : <DownOutlined style={{ pointerEvents: 'none' }} />}
            </span>
          </Button>
          <Navbar.Brand className='brand'>
            <NavLink to="/home">
              <img src={RTLLSS} width={200} alt='logo-RealtimeLSS' loading="lazy"></img>
            </NavLink>
          </Navbar.Brand>
          <div id="responsive-navbar-nav" className={expand ? "animateImage" : "animateImage2"}>
            <Nav id="responsive-navbar-nav" style={{ display: location.pathname === "/home" || location.pathname === "/" ? 'none' : "flex" }}>
              <>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to={`/mpe-ael-calc/${selectedStandard}`}
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                    >
                      {/* <CalculatorOutlined /> Point and Extended */}
                      <img src={PointnadExtendedP} height={300}></img>
                      <div class="intro2">
                        <h1>Point & Extended</h1>
                        <p>The point source has a laser emission from a single point and has a miniscule diameter
                          compared with the viewing distance while an extended source is the opposite with
                          laser emitted from various points and a relatively large diameter.
                        </p>
                      </div>
                    </NavLink>
                    <NavLink to={`/mpe-ael-calc/${selectedStandard}`} className="link"> <h1>Point & Extended</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to={`/fiber-optic/${selectedStandard}`}
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                    >
                      <img src={FiberOpticP} height={300}></img>
                      <div class="intro2">
                        <h1>Fiber Optic</h1>
                        <p>Utilises flexible quartz or glass fibres that use total internal reflection to pass light through
                          thousands of glancing reflections. Optical sources typically used are laser diodes,
                          LEDs and VCSEL (Vertical Cavity Surface Emitting Laser).
                        </p>
                      </div>
                      {/* <AimOutlined /> Fiber Optic */}
                    </NavLink>
                    <NavLink to={`/fiber-optic/${selectedStandard}`} className="link"> <h1>Fiber Optic</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to={`/diffuse-reflective/${selectedStandard}`}
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active card2 activeCard" : "hover-underline-animation card2")}
                    >
                      <img src={DiffuseReflection2} height={300}></img>
                      {/* <SelectOutlined /> Diffuse Reflection */}
                      <div class="intro2">
                        <h1>Diffuse Reflection</h1>
                        <p>This happens when different parts of a beam strike a surface and reflects over a wide range of angles.
                          The reflection typically strikes a rough or irregular surface.
                        </p>
                      </div>
                    </NavLink>
                    <NavLink to={`/diffuse-reflective/${selectedStandard}`} className="link"> <h1>Diffuse Reflection</h1></NavLink>
                  </Nav.Link>
                </div>
                <div>
                  <Nav.Link href="#">
                    <NavLink
                      to={`/lookup-table/${selectedStandard}`}
                      className={({ isActive }) => (isActive ? "gradient-border-bottom active  card2 activeCard" : "hover-underline-animation card2")}
                    >
                      <img src={LookupTableP} height={300}></img>
                      {/* <SearchOutlined /> Lookup Table */}
                      <div class="intro2">
                        <h1>Lookup Table</h1>
                        <p>Find out the accessible emission limit for all laser class in relation to the laser wavelength you want to calculate.
                          Evaluate the laser properties to determine safety values for eye and skin.
                        </p>
                      </div>
                    </NavLink>
                    <NavLink to={`/lookup-table/${selectedStandard}`} className="link"> <h1>Lookup Table</h1></NavLink>
                  </Nav.Link>
                </div>
              </>
            </Nav>
          </div>
        </Navbar>
      </>
    </>

  );
}


export default Nav_bar 