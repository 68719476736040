import { useState, useEffect } from 'react';
import axios from 'axios';

const FetchLaserProfile = (selectedStandard, isLocal,) => {
  const [myLaserProfile, setMyLaserProfile] = useState(null)
  const [laserProfileIsFetching, setlaserProfileIsFetching] = useState(true)

  useEffect(() => {
    console.log(`Laser profile is fetching..`)
    setlaserProfileIsFetching(true)
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/fetchLaserProfile';
        } else {
          return 'api/fetchLaserProfile';
        }
      })(),
      data: { selectedStandard },
      withCredentials: true
    })
      .then((res) => {
        console.log(`Laser Profile: `, res.data)
        setMyLaserProfile(res.data);
      })
      .catch((err) => {
        console.error("Error in network request:", err);
      })
      .finally(() => {
        setlaserProfileIsFetching(false)
      })
  }, [selectedStandard]);

  return ({ myLaserProfile, laserProfileIsFetching })
}

export default FetchLaserProfile