import { Alert, Button, Form, Input, Layout, Row, Card, Typography, Flex, Checkbox } from 'antd'
import Password from 'antd/es/input/Password'
import React, { useContext, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import miniIcon from '../assets/Logo/logosidetext.png'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import Axios from 'axios'
import { FooterHandler } from '../components/Footer/Footer'
import { EnvironmentContext } from '../routes/EnvironmentContext'

const Register = (props) => {
    const { isLocal } = useContext(EnvironmentContext)

    const navigate = useNavigate()
    const year = new Date().getFullYear();
    const [form] = Form.useForm()
    const [errorMessage, setErrorMessage] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const initialValues = {
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    const handleSubmit = () => {
        setIsSubmitting(true)
        setErrorMessage(null)

        let url = () => {
            if (isLocal === true) {
                return 'http://localhost:8000/register'
            } else {
                return 'api/register'
            }
        }

        Axios({
            method: 'post',
            url: url(),
            data: form.getFieldsValue(),
            withCredentials: true
        })
            .then((response) => {
                // this branch means the email has been sent and will now transfer user to success message.
                navigate('/confirm', { state: { data: form.getFieldsValue() } })
            })
            .catch((error) => {
                setIsSubmitting(false)
                setErrorMessage(error.response.data.message)
            })


    };
    return (
        <>
            <Row justify='center' align='middle' style={{ backgroundColor: 'white', height: "100vh", }}>
                <Card
                    style={{
                        // margin: '72px 0',
                        width: 600,

                        // height: "auto",
                        // fontWeight: '500',
                        // border: "none",
                        // fontWeight: '500',
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}
                    cover={
                        <Flex justify='center' align='center' style={{ marginBottom: "60px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                            <img
                                justify='center' align='center'
                                src={miniIcon}
                                style={{ width: "90%", justifySelf: "center" }}
                            />
                        </Flex>
                    }>
                    <Form
                        initialValues={initialValues}
                        onFinish={handleSubmit}
                        labelAlign={'right'}
                        labelCol={{ span: 7 }}
                        form={form}
                        style={{ display: "grid" }}>

                        <Form.Item
                            name='fullName'
                            label='Full Name'
                            required='true'
                            style={{ marginBottom: "0px" }}>
                            <Form.Item
                                name='firstName'
                                style={{ display: 'inline-block', width: 'calc(50% - 8px' }}
                                rules={[{ required: true, message: 'First name required!' }]}>
                                <Input placeholder='First name' />
                            </Form.Item>

                            <Form.Item
                                name='lastName'
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                rules={[{ required: true, message: 'Last name required!' }]}>
                                <Input placeholder='Last name' />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            name='username'
                            label='Username'
                            rules={[
                                { required: true, message: 'Please provide a username.' },
                                {
                                    validator: (_, username) => {
                                        const reservedWords = ["admin", "root", "superuser", "system"];
                                        const usernameRegex = /^(?!.*[_.]{2})[a-zA-Z0-9][a-zA-Z0-9._]{1,18}[a-zA-Z0-9]$/;
                                        if (username.length < 4) {
                                            return Promise.reject("Username must be greater than 3 characters!");
                                        }
                                        if (!usernameRegex.test(username)) {
                                            return Promise.reject("Invalid username! Use only letters, numbers, dots, and underscores. No consecutive dots or underscores. Dots or underscore can't be the start or end of the username.");
                                        }
                                        if (reservedWords.includes(username.toLowerCase())) {
                                            return Promise.reject(`"${username}" is a reserved word and cannot be used as a username.`);
                                        }

                                        return Promise.resolve();
                                    }
                                },

                            ]}>
                            <Input placeholder='Username' />
                        </Form.Item>


                        <Form.Item name='email'
                            label='Email'
                            rules={[{ required: true, message: 'The activation key is sent in this email so it is important to have a valid email address entered here.' }, { type: 'email', message: 'Invalid email!' }]}>
                            <Input placeholder='test@example.com' />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    validator: (_, password) => {
                                        let uppercaseCheck = /(?=.*[A-Z])/
                                        if (!password.match(uppercaseCheck)) {
                                            return Promise.reject(new Error("At least one uppercase letter"));
                                        }
                                        return Promise.resolve()
                                    }
                                },
                                {
                                    validator: (_, password) => {
                                        let lowercaseCheck = /(?=.*[a-z])/
                                        if (!password.match(lowercaseCheck)) {
                                            return Promise.reject(new Error("At least one lowercase letter"));
                                        }
                                        return Promise.resolve()
                                    }
                                },
                                {
                                    validator: (_, password) => {
                                        let digitCheck = /(?=.*[0-9])/
                                        if (!password.match(digitCheck)) {
                                            return Promise.reject(new Error("At least one number"));
                                        }
                                        return Promise.resolve()
                                    }
                                },
                                {
                                    validator: (_, password) => {
                                        let minCheck = /(?=.{8,})/
                                        if (!password.match(minCheck)) {
                                            return Promise.reject(new Error("Minimum 8 characters"));
                                        }
                                        return Promise.resolve()
                                    }
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password autoComplete='new-password' placeholder='Password' />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Confirm Password' />
                        </Form.Item>
                        <Form.Item
                            name="consent"
                            valuePropName="checked"
                            required='true'
                            label=""
                            style={{ justifySelf: "center" }}
                        >
                            <Checkbox required='true'>
                                <Typography.Text type='secondary'>
                                    I have read and agree to the <a href="/terms-and-conditions" target='_blank' rel="noreferrer">Terms and Conditions</a> and
                                    <a href="/privacy-policy" target='_blank' rel="noreferrer" style={{ textDecoration: "none" }}> Privacy Policy</a>.
                                </Typography.Text>
                            </Checkbox>
                        </Form.Item>

                        <Button loading={isSubmitting} htmlType='submit' type='primary' size='large' block>Register</Button>
                        {errorMessage !== null ? (
                            <Alert
                                style={{ margin: '24px 0' }}
                                description={errorMessage}
                                type='error'
                                showIcon
                            />
                        ) : null}
                        <Row justify='center' style={{ marginTop: "15px" }}>
                            <Row>
                                <Typography>
                                    Already have an account? <NavLink to='/'>Login</NavLink> here.
                                </Typography>
                            </Row>
                        </Row>
                    </Form>
                </Card>
            </Row>
            <FooterHandler />
            {/* <Footer style={{
                textAlign: 'center',
                backgroundColor: '#3a4e84',
                color: '#f5f5f5',
                fontSize: '16px'
            }}
            >
                Lasermet Philippines © {year}. Created by Lasermet Philippines.
            </Footer> */}
        </>
    )
}

export default Register