export const pointAndExtended = {
    laserOperation: 'cw',
    sourceType: 'point',
    extendedSettings: {
        sourceInput: 'apparentSourceSize',
        apparentSourceSize: {
            num: 5,
            unit: 'm'
        }
    },

    cw: {
        wavelength: {
            num: 633,
            unit: 'n'
        },
        exposureDuration: {
            num: 10,
            unit: false
        },
        power: {
            num: 3,
            unit: 'W'
        },
        beamDiameter: {
            num: 5,
            unit: 'm'
        },
        beamDivergence: {
            num: 2.5,
            unit: 'm'
        },
        distance: {
            num: 30,
            unit: false
        }
    },
    repetitivelyPulsed: {
        wavelength: {
            num: 1064,
            unit: 'n'
        },
        time: {
            num: 11,
            unit: false
        },
        beamDiameter: {
            num: 5,
            unit: 'm'
        },
        beamDivergence: {
            num: 10,
            unit: 'm'
        },
        distance: {
            num: 30,
            unit: false
        },
        outputUnit: 'averagePower',
        averagePower: {
            num: 1,
            unit: false
        },
        pulseDuration: {
            num: 10,
            unit: 'n'
        },
        pulseFrequency: {
            num: 1,
            unit: 'k'
        },
        beamDiameter: {
            num: 5,
            unit: 'm'
        }
    }
}

