import React, { createContext, useCallback, useEffect, useState } from 'react'
import FetchLaserProfile from '../components/MyLaserProfileFetch';
import Cookies from 'js-cookie'

const EnvironmentContext = createContext()

const EnvironmentProvider = ({ isLocal, children }) => {
    const [selectedStandard, setSelectedStandard] = useState('iec')
    const [email, setEmail] = useState();
    const [otp, setOTP] = useState();
    const [myUser, setMyUser] = useState({})
    const { laserProfileIsFetching, myLaserProfile } = FetchLaserProfile(selectedStandard, isLocal)

    // useEffect(() => {
    //     console.log(`myUser was changed.`, myUser)
    // }, [myUser])

    // Logs messages to the console only in the local development environment
    const log = (...args) => {
        if (isLocal) {
            console.log(...args)
        }
    }

    const handleLogout = () => {
        setMyUser(null)
    }


    return (
        <EnvironmentContext.Provider
            value={{ isLocal, otp, setOTP, setEmail, email, setMyUser, myUser, myLaserProfile, laserProfileIsFetching, handleLogout, log, selectedStandard, setSelectedStandard }}
        >
            {children}
        </EnvironmentContext.Provider>
    )
}

export { EnvironmentContext, EnvironmentProvider }