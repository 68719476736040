import { Alert, Button, Form, Input, InputNumber, Result, Row, Typography, Flex, Card } from 'antd'
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Axios from 'axios'
import { FooterHandler } from '../components/Footer/Footer'
import { EnvironmentContext } from '../routes/EnvironmentContext'

const RegistrationSuccess = (props) => {
    const [queryParameters] = useSearchParams()
    const { isLocal } = useContext(EnvironmentContext)
    // const { state: { data: { username: username } } } = useLocation()
    const location = useLocation()
    const username = location?.state?.data?.username
    // const isLocal = props.isLocal
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const navigate = useNavigate()
    let initialValues = {
        username: username,
        confirmationCode: null
    }

    if (props.username) {
        initialValues.username = props.username
    }

    const usernameParams = queryParameters.get("username")
    const confirmationCodeParams = queryParameters.get("confirmationCode")
    useEffect(() => {
        if (usernameParams && confirmationCodeParams) {
            form.setFieldValue('username', usernameParams)
            form.setFieldValue('confirmationCode', confirmationCodeParams)
        }
    }, [])


    let url = () => {
        if (isLocal === true) {
            return 'http://localhost:8000/confirm'
        } else {
            return 'api/confirm'
        }
    }

    const handleSubmit = () => {
        setIsSubmitting(true)
        Axios({
            method: 'post',
            url: url(),
            data: form.getFieldsValue(),
            withCredentials: true
        }).then((response) => {
            navigate('/activation-success', { state: { fromConfirm: true } })
        }).catch((error) => {
            console.error(error.response.data.error)
            setErrorMessage(error.response.data.error)
        }).finally(() => {
            setIsSubmitting(false)
        })
    }
    return (
        <>
            <Row justify='center' align='middle' style={{ backgroundColor: 'white', height: '100vh',}}>
                <Card
                    style={{
                        background: 'white',
                        width: 600,
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}
                >
                    <Result
                        status='success'
                        title={'Confirm Your Account!'}
                        subTitle='Please check your inbox or spam folder for the confirmation code and enter it below.'
                        // subTitle='Check your email for the confirmation code and enter it here:'
                        style={{
                            // width: "100vw",
                            background: 'white',
                            height: "auto",
                            fontWeight: '500',
                            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

                        }}
                        extra={[
                            <Row justify='center'  >
                               
                                    <Form
                                        initialValues={initialValues}
                                        onFinish={handleSubmit}
                                        form={form}
                                        style={{ width: '100%', }}
                                        labelCol={{ span: 7 }}>
                                        <Form.Item
                                            name='username'
                                            label='Username'
                                            rules={[{ required: true, message: 'Please enter your username' }]}>
                                            <Input size='large' />
                                        </Form.Item>
                                        <Form.Item
                                            name='confirmationCode'
                                            label='Confirmation Code'
                                            rules={[{ required: true, message: 'Check your email for the activation code' }]}>
                                            <Input size='large' />
                                        </Form.Item>
                                        <Button htmlType='submit' loading={isSubmitting} type='primary' block size='large'>Activate Account</Button>
                                    </Form>
                                    {errorMessage !== null ? (
                                        <Row style={{ margin: '16px 0' }} justify={'center'}>
                                            <Alert
                                                type='error'
                                                description={errorMessage}
                                                showIcon
                                            />
                                        </Row>
                                    ) : null}
                                    <Row style={{ margin: '8px 0' }} justify={'center'}><Typography>Account already activated? <NavLink to='/'>Login</NavLink> here!</Typography></Row>
                              
                            </Row>
                        ]}
                    />
                </Card>
            </Row>
            <FooterHandler />
        </>
    )
}

export default RegistrationSuccess