import { useContext, useEffect } from 'react';
import './App.css';
import { useLocation, Outlet } from 'react-router-dom'
import { Layout, Spin, Flex } from 'antd';
import Cookies from 'js-cookie';
import Nav_bar from './components/Navbar';
import { EnvironmentContext } from './routes/EnvironmentContext';
const { Header, Content } = Layout;

// const rtllSettings = { expires: 365 }
const rtllSettings = { expires: 365 }



function App() {
  // change to false if you are planning to upload to render.
  if (!Cookies.get('RTLSS_SETTINGS')) Cookies.set(`RTLSS_SETTINGS`, JSON.stringify({}), rtllSettings)
  const { setMyUser, laserProfileIsFetching, log } = useContext(EnvironmentContext)
  const { pathname } = useLocation();


  const pathsWithoutHeaders = ['/login', '/register', '/confirm', '/forgotPassword', '/logout', '/verifyCode', '/forgot-change-password', '/activation-success' ]


  // This code checks if a cookie named 'RTLSS_user' exists. If it does, it processes the cookie's value to extract user information. 
  // If the value starts with 'j:', it removes the prefix and parses the remaining JSON string to set the user state. 
  // If no 'j:' prefix exists, the full cookie value is parsed directly. 
  // If the cookie doesn't exist, the user state is set to null.
  useEffect(() => {
    log(`Pathname: `, pathname)
    if (Cookies.get('RTLSS_user')) {
      let rtlssUser = Cookies.get(`RTLSS_user`)
      if (rtlssUser.includes('j:')) {
        rtlssUser = rtlssUser.split('j:')[1]
        log(`RTLSS UER: `, rtlssUser)
        setMyUser(JSON.parse(rtlssUser))
      } else {

        log(`parsing............................`, Cookies.get('RTLSS_user'))
        setMyUser(JSON.parse(Cookies.get('RTLSS_user')))
      }
    }
    // else {
    //   log(`RTLSS UER: `, Cookies.get("RTLSS_user"))
    //   setMyUser(null)
    // }
  }, [pathname])

  return (
    laserProfileIsFetching ? (
      <Flex justify='center' align='center' style={{ width: '100%', height: '100vh' }}>
        <Spin size='large' />
      </Flex> //spinner
    ) : (
      <>
        {
          !pathsWithoutHeaders.includes(pathname) && (
            <Header style={{ position: "relative", display: null }}>
              <Nav_bar />
            </Header>
          )
        }
        <Content style={{ height: '100vh', position: 'static' }}>
          <Outlet context={{ pathname: pathname }} />
        </Content>
      </>
    )
  );

}

export default App;
