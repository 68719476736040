import FO_CWCalculator from "./FiberOptic/FO_CWCalculator";
import FO_SinglePulseCalculator from "./FiberOptic/FO_SinglePulseCalculator"
import FO_RepetitivelyPulsedCalculator from "./FiberOptic/FO_RepetitivelyPulsedCalculator"
import { Button, Card, Col, Form, Radio, Row, Select, Space, Table, Drawer, Modal, message, Input, Flex, Tooltip, Typography, Spin } from 'antd'
import { DownloadOutlined, CloseOutlined, SettingOutlined, SaveTwoTone, FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useRef, useContext } from "react";
import Field from "../../../components/Field";
import Cookies from "js-cookie";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { ResultFOCWsingleMode } from "./Results/FiberOptics_Results/ResultFO_CWsingleMode";
import { ResultFOCWmultiModeSI } from "./Results/FiberOptics_Results/ResultFO_CWmultiModeSI";
import { ResultFOCWmultiModeGI } from "./Results/FiberOptics_Results/ResultFO_CWmultiModeGI";
import { ResultFOsinglePulseSM } from "./Results/FiberOptics_Results/ResultFO_SinglePulseSM";
import { ResultFOsinglePulseMSI } from "./Results/FiberOptics_Results/ResultFO_SinglePulseMSI";
import { ResultFOsinglePulseMGI } from "./Results/FiberOptics_Results/ResultFO_SinglePulseMGI"
import { ResultFORepetitivelyPulsedSM } from "./Results/FiberOptics_Results/ResultFO_RepetitvelyPulsedSM";
import { ResultFORepetitivelyPulsedMSI } from "./Results/FiberOptics_Results/ResultFO_RepetitivelyPulsedMSI";
import { ResultFORepetitivelyPulsedMGI } from "./Results/FiberOptics_Results/ResultFO_RepetitivelyPulsedMGI";
import { AddLaserProfile } from "../../../components/LaserProfile/AddLaserProfile";
import FetchLaserProfile from '../../../components/MyLaserProfileFetch'
import FetchCalculations from "../../../components/FetchCalculations";
import { CalculationStatusModal } from '../../../components/CalculationStatusModal'
import { ReactComponent as Bars } from '../../../assets/Bars.svg'
import { ReactComponent as Wedges } from '../../../assets/Wedges.svg'
import { ConfirmCalculateModal } from '../../../components/ConfirmCalculateModal'
import Loader from '../../../assets/Loader'
import './Calcs.css'
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ResultsPDF } from "../../../components/PDF/ResultsPDF";
import SettingsModal from "./SettingsModal";

import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import { FooterHandler } from "../../../components/Footer/Footer";
import { debounce } from "lodash";
import { EnvironmentContext } from "../../../routes/EnvironmentContext";
import ErrorPage from "../../ErrorPages/ErrorPage";
import StandardHandler from "./StandardHandler";


export const textCenter = { display: "flex", justifyContent: "center" };
const { TextArea } = Input;



const myInitialValues = {
  calculatorType: "FiberOptic",
  laserOperation: "FO_cw",
  sourceType: "singlemode",
  FO_cw: {
    wavelength: {
      unit: "n",
    },
    power: {
      unit: false,
    },
    time: {
      unit: false,
    },
    diameter: {
      unit: "u",
    },
    distance: {
      unit: false,
    },
  },

  FO_singlePulse: {
    wavelength: {
      unit: "n",
    },
    distance: {
      unit: false,
    },
    energy: {
      unit: false,
    },
    power: {
      unit: false,
    },
    duration: {
      unit: 'm',
    },
    diameter: {
      unit: "u",
    },
  },

  FO_repetitivelyPulsed: {

    outputUnits: "peakPower",

    wavelength: {
      unit: "n",
    },
    distance: {
      unit: false,
    },
    energy: {
      unit: false,
    },
    peakPower: {
      unit: false,
    },
    averagePower: {
      unit: false,
    },
    time: {
      unit: false,
    },
    duration: {
      unit: 'm',
    },
    diameter: {
      unit: "u",
    },
    pulseFrequency: {
      unit: false
    }

  }
};
const FiberOptic = () => {
  const { isLocal, myUser, setMyUser, selectedStandard } = useContext(EnvironmentContext)
  const [form] = Form.useForm();
  const [results, setResults] = useState(null);
  const [result, setResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [units, setunits] = useState("joules")
  const [messageApi, contextHolder] = message.useMessage()
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form3] = Form.useForm()
  const [laserProfileForm] = Form.useForm()
  const [laserProfileSaveVisible, setLaserProfileSaveVisible] = useState(false)
  const [laserprofiledebug, setlaserprofiledebug] = useState(null)
  const [submitProfileIsLoading, setSubmitProfileIsLoading] = useState(false)
  const [isUsingLaserProfile, setIsUsingLaserProfile] = useState(false)
  const [form2] = Form.useForm()
  const [initialValues, setInitialValues] = useState(myInitialValues)
  const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(selectedStandard, isLocal)
  const [numberOfCalcs2, setNumberOfCalcs2] = useState(null)
  const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial } = FetchCalculations(isLocal, myUser, numberOfCalcs2)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [openBuy, setOpenBuy] = useState(false)

  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [isFullScreen, setisFullScreen] = useState(false)
  const [laserName, setLaserName] = useState("")
  const [laserForm] = Form.useForm()
  const tempName = useRef("");

  const toggleFullscreen = () => {
    setisFullScreen((prevFullScreen) => !prevFullScreen)
  }

  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '#save-laser',
        popover: {
          title: 'Save Laser',
          description: 'Clicking this button will save the laser after providing a description for it.'
        }
      }
    ]
  })

  {/*Lets the user calculate if numberofcalculations !== 0 */ }
  const checkStatus = () => {
    {/*Prompts user to buy more calculations if numberofcalculations === 0 */ }
    if (isDisabled === true && isSubscribed === false && isFreeTrial === null) {
      setOpenBuy(true)
    }
    else if (isDisabled === false || isSubscribed === true || isFreeTrial === true) {
      const { hide_confirmation } = JSON.parse(Cookies.get('RTLSS_SETTINGS'))
      if (hide_confirmation) {
        submitForm()
      } else {
        // {/*Prompts the user to proceed with the calculation */ }
        showPopconfirm(true)
      }
    }
  }

  const handleCancelCheckStatus = () => {
    {/**Close calculation status modal */ }
    setOpenBuy(false)
  };

  useEffect(() => {
    if (numberOfCalcs !== null) {
      setSpinning(false);
    } else {
      setSpinning(true);
    }
  }, [numberOfCalcs]);

  const showPopconfirm = () => {
    setIsConfirmOpen(true);
  };
  const handleCancelPopConfirm = () => {

    setIsConfirmOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);

  };
  const onUnitChange = (e) => {
    setunits(e.target.value)
  }

  const onValuesChange = () => {
    setResult(null)
    form2.resetFields()
  }

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    });
  };
  const showError = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
      duration: 4
    })
  }
  let data = form.getFieldsValue()
  const submitForm = async () => {
    setIsConfirmOpen(false);
    setIsSubmitting(true);


    const data = form.getFieldsValue();
    let url = () => {
      if (isLocal === true) {
        return "http://localhost:8000/calculate";
      } else {
        return "api/calculate";
      }
    };
    axios({
      method: "post",
      url: url(),
      withCredentials: true,
      headers: { Authorization: `Bearer ${Cookies.get("RTLSS_TOKEN")}` },
      data: { data, numberOfCalcs, isFreeTrial, isSubscribed }
    })
      .then((res) => {
        setResult(form.getFieldsValue());
        setIsSubmitting(false);
        setResult(res.data.calcResult);
        setNumberOfCalcs2(res.data.newNumberOfCalculations)
        setChangeLaser("asd")
      })
      .catch((err) => {
        navigate("/logout");
      });
  };

  const handleOk = () => {
    setLoading(true);

    let allReports = { ...form3.getFieldValue(), ...form.getFieldsValue(), result }
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/report'
        }
        else {
          return 'api/report'
        }
      })(),
      withCredentials: true,
      data: { data: allReports }
    }).then((res) => {

      success(res.data.message)

    }).catch((err) => {
      console.error(err)
      showError(err.response.data.message)
    }).finally(() => {
      setLoading(false);
      form3.resetFields()
      setIsModalOpen(false)
    })

  };

  const handleCancel = () => {
    form3.resetFields()
    setIsModalOpen(false);
  };
  useEffect(() => {
    form.resetFields()
  }, [initialValues])
  const handleSaveLaser = () => {

    setLaserProfileSaveVisible(true)
  }

  const handleConfirmSaveLaser = () => {
    setSubmitProfileIsLoading(true)
    laserProfileForm.setFieldValue(['laserProfile', 'data'], form.getFieldsValue())
    setlaserprofiledebug(JSON.stringify(laserProfileForm.getFieldsValue(), null, 2))
    // Save this to database.
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/add-profile'
        } else {
          return 'api/add-profile'
        }
      })(),
      withCredentials: true,
      data: { data: laserProfileForm.getFieldsValue(), calculatorType: initialValues.calculatorType }
    }).then((res) => {
      success(res.data.message)
      setMyUser({ ...myUser, laserProfile: res.data.response.rows })
    }).catch((err) => {
      showError(err.response.data.message)
    }).finally(() => {
      setLaserProfileSaveVisible(false)
      setSubmitProfileIsLoading(false)
      laserProfileForm.resetFields()
    })

  }
  const date = new Date().toLocaleString().split(',')[0]
  const [pdfContent, setPDFContent] = useState(null)

  const handleDownload = () => {

    if (result) {
      const pdfContent = (
        <ResultsPDF result={result} date={date} form={form.getFieldsValue()} laserName={laserName} />
      )

      return (

        <PDFDownloadLink document={pdfContent} fileName={`${laserName || 'Fiber-Optic-Results'}.pdf`}>
          {({ blob, url, loading, error }) => (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              disabled={!laserName.trim()}
              onClick={() => {
                laserForm.resetFields()
                setLaserName("")
                laserForm.resetFields()
              }}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >
              Download Results
            </Button>
          )}
        </PDFDownloadLink>

        // {/* <PDFDownloadLink document={
        //   <Document>
        //     <Page size="LETTER" style={stylez}>
        //       <Svg viewBox={`0 0 595.3 841.9`}>
        //         {svgComponent}
        //       </Svg>

        //     </Page>
        //   </Document>
        // }>
        //   {({ blob, url, loading, error }) => (
        //     loading ? <Spin /> : <Button icon={<DownloadOutlined />}>Download Results V2</Button>
        //   )}
        // </PDFDownloadLink> 


      )
    }
  };


  const handleInputChange = debounce((value) => {
    // console.log("ey", value)
    setLaserName(value);
  }, 300)

  const [changeLaser, setChangeLaser] = useState("")

  useEffect(() => {
    // console.log("ey", laserName)
    if (result) {
      // console.log("eyer")
      setPDFContent(<ResultsPDF result={result || {}} date={date || {}} form={form.getFieldsValue()} laserName={laserName} />)
    }
  }, [changeLaser, laserName])
  return (
    <StandardHandler>
      <>
        {contextHolder}

        <AddLaserProfile laserProfileForm={laserProfileForm}
          laserProfileSaveVisible={laserProfileSaveVisible}
          setLaserProfileSaveVisible={setLaserProfileSaveVisible}
          submitProfileIsLoading={submitProfileIsLoading}
          handleConfirmSaveLaser={handleConfirmSaveLaser} />

        <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position: "absolute", left: "50%", left: "50%", transform: "translate(-41%, -5%)" }} />}>
          <div class="calcs">
            <Spin spinning={isSubmitting} indicator={<Loader />} style={{ zIndex: 10000, fontSize: "500px" }}>
              {numberOfCalcs !== null ? (
                <>
                  <Row justify="center">
                    <Col
                      lg={result === null ? 24 : 12}
                      sm={24}
                      style={{
                        padding: "15px 20px",
                        display: (() => {
                          if (isFullScreen) {
                            return "none"
                          }
                          else {
                            return null
                          }
                        })()
                      }}
                    >
                      <Row justify={"center"}>
                        <Col lg={result === null ? 10 : 18} md={24}>
                          <Card
                            title={<h4>Fiber Optic Calculator</h4>}
                            style={{ width: "100%", display: "block", marginTop: "60px" }}
                            extra={
                              result != null ? (
                                <>
                                  <Tooltip
                                    mouseEnterDelay={0.5}
                                    destroyTooltipOnHide
                                    showArrow
                                    placement='left'
                                    title={`Click me to save this laser setup.`}>
                                    <Button id='save-laser' type='text' onClick={handleSaveLaser} icon={<SaveTwoTone />} />
                                  </Tooltip>
                                  <Button
                                    type="text"
                                    icon={<CloseOutlined />}
                                    onClick={() => {
                                      setShowSettingsModal(true)
                                    }}
                                  />
                                </>

                              ) : (
                                <Button
                                  type="text"
                                  icon={<SettingOutlined />}
                                  onClick={() => {
                                    setShowSettingsModal(true)
                                  }}
                                />
                              )}
                          >

                            <Form
                              form={form2}
                              labelCol={{ span: 8 }}>
                              <Form.Item label='Saved Laser' name='laserProfile'>
                                <Select placeholder='Select from saved lasers'
                                  options={(() => {
                                    if (myLaserProfile?.laserProfile) {
                                      const { laserProfile } = myLaserProfile
                                      let filterProfile = laserProfile.filter(laserProfile => laserProfile.calculatortype === "FiberOptic")
                                      return filterProfile.map((profile, index) => ({
                                        key: index,
                                        label: profile.description,
                                        value: JSON.stringify(profile.data)
                                      }))
                                    } else {
                                      return null
                                    }
                                  })()}
                                  disabled={(() => {
                                    if (myLaserProfile?.laserProfile.length !== 0) {
                                      return false
                                    } else {
                                      return true
                                    }
                                  })()}
                                  onChange={(props) => {
                                    setInitialValues({ ...myInitialValues, ...JSON.parse(props) })
                                    setIsUsingLaserProfile(true)
                                    setResult(null)
                                  }}
                                />
                              </Form.Item>
                            </Form>
                            <Form
                              onValuesChange={onValuesChange}
                              form={form}
                              initialValues={initialValues}
                              name="laserSafety"
                              onFinish={checkStatus}
                              labelCol={{ span: 8 }}
                            >
                              <Form.Item name="laserOperation" label="Laser Operation" onChange={(e) => setChangeLaser(e.target.value)}>
                                <Radio.Group optionType="default">
                                  <Radio.Button value="FO_cw">CW</Radio.Button>
                                  <Radio.Button value="FO_singlePulse">
                                    Single Pulse
                                  </Radio.Button>
                                  <Radio.Button value="FO_repetitivelyPulsed">
                                    Repetitively Pulsed
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              <Form.Item label="Source Type" name="sourceType" onChange={(e) => setChangeLaser(e.target.value)}>
                                <Radio.Group optionType="default">
                                  <Radio.Button value="singlemode">
                                    Single Mode
                                  </Radio.Button>
                                  <Radio.Button value="multimodeSI">
                                    Multimode-Step Index
                                  </Radio.Button>
                                  <Radio.Button value="multimodeGI">
                                    Multimode-Graded Index
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              {/* Note below, I added an if statement to check if anything has changed with the form before I render it. */}
                              {/* Without it, the program will re-render everything inside the Form.Item, and in turn, lag. :P */}
                              <Form.Item noStyle shouldUpdate={(prevItem, currItem) => prevItem.laserOperation !== currItem.laserOperation}>
                                {({ getFieldsValue }) => {
                                  let laserOperation =
                                    getFieldsValue(['laserOperation']).laserOperation;
                                  return laserOperation === "FO_cw" ? (
                                    <FO_CWCalculator />
                                  ) : laserOperation === "FO_singlePulse" ? (
                                    <FO_SinglePulseCalculator />
                                  ) : laserOperation === "FO_repetitivelyPulsed" ? (
                                    <FO_RepetitivelyPulsedCalculator />
                                  ) : null;
                                }}
                              </Form.Item>

                              <div
                                class="hover-underline-animation2"
                                style={{ width: "100%" }}
                              >
                                <Button
                                  style={{
                                    margin: "24px 0 0",
                                    background: '#1d1f8c'
                                  }}
                                  htmlType="submit"
                                  block
                                  type="primary"
                                  loading={isSubmitting}>
                                  Calculate
                                </Button>
                              </div>
                              {isSubscribed === false ? (
                                <>
                                  <Row justify={'end'} style={{ marginTop: "1%" }}>
                                    <Typography.Text type='secondary' style={{ fontSize: "20px" }}>
                                      {isFreeTrial ? <i>Free Trial</i> : <i>Remaining Calculations: <strong style={{ color: "black" }}>{numberOfCalcs}</strong></i>}
                                    </Typography.Text>
                                  </Row>
                                  {numberOfCalcs < 6 && !isFreeTrial ? (
                                    <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "15px" }}>Running low on calculations? Get more calculations <NavLink to={`/home?defKey=${"4"}&handleClick=${"billing"}`}>here!</NavLink></Typography.Text></Row>
                                  ) : null}
                                </>
                              ) :
                                <Row justify={'end'} style={{ marginTop: "1%" }}><Typography.Text type='secondary' style={{ fontSize: "20px" }}><i>Unlimited Calculations</i></Typography.Text></Row>}

                              {result && (
                                <Flex justify='right'>
                                  <Button
                                    type='text'
                                    onClick={() => {
                                      driverObj.drive()
                                    }}
                                  >
                                    <Typography.Text italic>Do you want to save this laser?</Typography.Text>
                                  </Button>
                                </Flex>
                              )}

                            </Form>
                          </Card>
                        </Col>
                      </Row>
                    </Col>

                    {result !== null ? (
                      <Col
                        lg={12}
                        sm={24}
                        style={{ padding: "15px 20px", width: "100vw" }}
                      >
                        <Row>
                          <Card
                            title="Calculation Results"
                            style={{ width: "90vw", marginTop: "60px" }}
                            extra={[
                              <>
                                <Space>
                                  <Radio.Group onChange={onUnitChange} value={units} buttonStyle="solid">
                                    <Radio.Button value="joules">Joules</Radio.Button>
                                    <Radio.Button value="watts">Watts</Radio.Button>
                                  </Radio.Group>
                                  <Tooltip destroyTooltipOnHide placement='top' showArrow mouseEnterDelay={0.5} title="Click me to hide inputs." zIndex={1}>
                                    <Button icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={toggleFullscreen} />
                                  </Tooltip>
                                </Space>
                              </>
                            ]}
                          >
                            {/* <Button type="primary" onClick={() => setOpen(true)}>
                        View in fullscreen
                      </Button> */}
                            {result.laserOperation === "FO_cw" ? (
                              <>
                                {result.sourceType === "singlemode" ? (
                                  <ResultFOCWsingleMode result={result} textCenter={textCenter} units={units} data={data}></ResultFOCWsingleMode>
                                ) : result.sourceType === "multimodeSI" ? (
                                  <ResultFOCWmultiModeSI result={result} textCenter={textCenter} units={units} data={data}></ResultFOCWmultiModeSI>
                                ) : result.sourceType === "multimodeGI" ? (
                                  <ResultFOCWmultiModeGI result={result} textCenter={textCenter} units={units} data={data}></ResultFOCWmultiModeGI>
                                ) : null}
                              </>
                            ) : result.laserOperation === "FO_singlePulse" ? (
                              <>
                                {result.sourceType === "singlemode" ? (
                                  <ResultFOsinglePulseSM result={result} textCenter={textCenter} units={units} data={data}></ResultFOsinglePulseSM>
                                ) : result.sourceType === "multimodeSI" ? (
                                  <ResultFOsinglePulseMSI result={result} textCenter={textCenter} units={units} data={data}></ResultFOsinglePulseMSI>
                                ) : result.sourceType === "multimodeGI" ? (
                                  <ResultFOsinglePulseMGI result={result} textCenter={textCenter} units={units} data={data}></ResultFOsinglePulseMGI>
                                ) : null}
                              </>
                            ) : result.laserOperation === "FO_repetitivelyPulsed" ? (
                              <>
                                {result.sourceType === "singlemode" ? (
                                  <ResultFORepetitivelyPulsedSM result={result} textCenter={textCenter} units={units} data={data}></ResultFORepetitivelyPulsedSM>
                                ) : result.sourceType === "multimodeSI" ? (
                                  <ResultFORepetitivelyPulsedMSI result={result} textCenter={textCenter} units={units} data={data}></ResultFORepetitivelyPulsedMSI>
                                ) : result.sourceType === "multimodeGI" ? (
                                  <ResultFORepetitivelyPulsedMGI result={result} textCenter={textCenter} units={units} data={data}></ResultFORepetitivelyPulsedMGI>
                                ) : null}
                              </>
                            ) : null}
                            <Row justify={"end"} style={{ margin: "25px 5px 5px 0" }}>
                              <Form form={laserForm}>
                                <Form.Item name='name'>
                                  <Input
                                    placeholder='Enter laser setup name'
                                    value={tempName}
                                    onChange={(e) => {
                                      tempName.current = e.target.value;
                                      handleInputChange(e.target.value)
                                    }}
                                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                  />
                                </Form.Item>
                              </Form>
                              {handleDownload()}
                            </Row>
                          </Card>
                          <Button block type='primary' danger style={{ marginTop: 10, width: "100%", whiteSpace: "normal", height: "auto", marginBottom: "50px" }} onClick={showModal}>Is there something wrong with the results? Click here to report a bug.</Button>

                          <Modal title="Report a Bug" onCancel={handleCancel} open={isModalOpen} footer={[null]}>
                            <Form form={form3} onFinish={handleOk}>
                              <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input a description." },]}>
                                <TextArea
                                  placeholder=""
                                  style={{
                                    height: 120,
                                    width: 350,
                                    resize: 'none',
                                  }} />
                              </Form.Item>
                              <Flex justify='flex-end' align='flex-end' style={{ marginBottom: -35 }}>
                                <Form.Item>
                                  <Button onClick={handleCancel}> Cancel</Button>
                                  <Button type='primary' htmlType="submit" loading={loading} style={{ marginLeft: 10 }} >Submit</Button>
                                </Form.Item>
                              </Flex>
                            </Form>
                          </Modal>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : null}
            </Spin>
          </div>
          <FooterHandler />
        </Spin>

        {/* Confirm Calculate */}
        <ConfirmCalculateModal isConfirmOpen={isConfirmOpen} handleCancelPopConfirm={handleCancelPopConfirm} submitForm={submitForm}></ConfirmCalculateModal>

        <SettingsModal showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />

        <CalculationStatusModal openBuy={openBuy} handleCancelCheckStatus={handleCancelCheckStatus}></CalculationStatusModal>
      </>
    </StandardHandler>
  );
}

export default FiberOptic