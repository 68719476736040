import { Card, Flex, Button, Row, Col, Typography } from "antd"
import axios from 'axios'
import { useState, useEffect, useContext } from "react"
import './plan.css'
import { FreeTrialCard } from "./FreeTrial/FreeTrialCard"
import { useNavigate } from "react-router-dom"
import { EnvironmentContext } from "../routes/EnvironmentContext"
// const cardStyle = { display: "flex", flexFlow: "column", width: "70%", height: "40vh", margin: " 10px", padding: "15px", border: "1px solid #E1E3E5", borderRadius: "7px", boxShadow: "2px 2px 6px 3px rgba(0, 0, 0 , 0.3)" }
// const contentBox = { backgroundColor: "#F0F0F0", position: 'relative', height: "32vh", paddingTop: "30px" }
const buttonStyle = { height: "5vh", width: "70%", minHeight: "5vh", height: "auto", fontSize: "1rem" }

const { Title, Text } = Typography


function SubscriptionPlan({ myUser, isSubscribed, isFreeTrial, freeTrialActivated, isPreAuthorized, userLocation, sethandleClick }) {
  const [spinner1, setSpinner1] = useState(false)
  const [spinner2, setSpinner2] = useState(false)
  const [spinner3, setSpinner3] = useState(false)
  const [spinner4, setSpinner4] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const navigator = useNavigate()
  const { isLocal, selectedStandard } = useContext(EnvironmentContext)

  useEffect(() => {
    if (isSubscribed === true) {
      setDisableButton(true)
    }
    else if (isFreeTrial === true) {
      setDisableButton(true)
    }
  }, [isSubscribed])

  const handleSubscription = (key) => {
    handleLoading(key)
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/create-checkout-session'
        }
        else {
          return 'api/create-checkout-session'
        }
      })(),
      withCredentials: true,
      data: { lookup_key: key, myUser: myUser }

    }).then((res) => {
      window.location.replace(res.data);
    }).catch((err) => {
      console.error(err)

    })
  }
  const handleSubscription2 = (key) => {
    setSpinner4(true)
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/create-checkout-session-subscription'
        }
        else {
          return 'api/create-checkout-session-subscription'
        }
      })(),
      withCredentials: true,
      data: { lookup_key: key, myUser: myUser, isSubscription: true }

    }).then((res) => {

      window.location.replace(res.data);
    }).catch((err) => {
      console.error(err)

    })
  }

  const handleFreeTrial = (key) => {
    setSpinner1(true)
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/create-checkout-session-trial'
        }
        else {
          return 'api/create-checkout-session-trial'
        }
      })(),
      withCredentials: true,
      data: { lookup_key: key, myUser: myUser }

    }).then((res) => {

      window.location.replace(res.data);
    }).catch((err) => {
      console.error(err)

    })
  }

  let [message, setMessage] = useState('');
  let [success2, setSuccess2] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const handleBilling = () => {
    // handleLoading(key)
    setSpinner4(true)
    const query = new URLSearchParams(window.location.search);

    setSessionId(query.get('session_id'));
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/create-portal-session'
        }
        else {
          return 'api/create-portal-session'
        }
      })(),
      withCredentials: true,
      data: { myUser: myUser }

    }).then((res) => {

      window.location.replace(res.data);
    }).catch((err) => {
      console.error(err)

    })
  }

  const handleLoading = (key) => {
    if (key === "1" || key === "1GBP" || key === "1EU") {
      setSpinner1(true)
    }
    else if (key === "2" || key === "2GBP" || key === "2EU") {
      setSpinner2(true)
    }
    else if (key === "3" || key === "3GBP" || key === "3EU") {
      setSpinner3(true)
    }
  }

  let prices
  let lookup_key
  if (userLocation?.timezone.includes("Europe") && userLocation.country !== "GB") {
    prices = ["€24", "€180", "€230", "€275"]
    lookup_key = ["1EU", "2EU", "3EU", "365EU"]
  }
  else if (userLocation?.country === "GB") {
    prices = ["£20", "£160", "£200", "£235"]
    lookup_key = ["1GBP", "2GBP", "3GBP", "365GBP"]
  }
  else {
    prices = ["$26", "$199", "$250", "$299"]
    lookup_key = ["1", "2", "3", "365"]
  }


  return (
    <>
       <Row justify={'center'} style={{ margin: '3% 0 3% 0' }} gutter={[16, 16]}>
        <Card style={{ width: "60vw", display: isFreeTrial !== null ? "none" : "grid"}}>
          <Row justify={'center'}>
            {isPreAuthorized === true ? (
            <>
            <FreeTrialCard freeTrialActivated={freeTrialActivated} spinner1={spinner1} disableButton={disableButton} handleFreeTrial={handleFreeTrial} buttonStyle={buttonStyle}></FreeTrialCard>
            </>) 
            :(<>
            {disableButton !== true ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <div class="creditBox creditBox1">
                    <Flex justify={"center"} style={{ height: "6vh", background: "linear-gradient(135deg, #444789 2%, #1d1f8c 19%, #1d1f8c 84%, #444789 98%)", marginBottom: "20%", whiteSpace: "normal" }}>
                      {/*Title**/}
                      {/* <div style={{position: "absolute", width:"100%", height:"20%", backgroundColor: "red"}}></div> */}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ display: "block", marginBottom: "-13px" }}>
                          <Typography><h1 style={{ color: "white", fontSize: "20px" }}>One Off </h1></Typography>
                        </span>
                        <Typography><h1 style={{ color: "white", fontSize: "20px" }}>Calculation </h1></Typography>
                      </div>
                    </Flex>
                    <Flex justify="center" align="center" style={{ height: "12vh" }}>
                      <div className="innerBox" style={{ border: "2px solid #1d1f8c"}}>
                        <Row justify="center" align="center" >
                          <h1 style={{ fontSize: "2rem", color: "#1d1f8c" }}>{prices[0]}</h1>
                          <text style={{fontSize:"13px", fontWeight:"bold"}}>
                          {userLocation?.country === "GB" ? 
                          "+VAT"
                          : ""}
                          </text>
                        </Row>
                        <Row>
                          <Typography style={{ marginTop: "-10px", justifyContent: "center", textAlign: "center" }}><p>1 Calculation</p> </Typography>
                        </Row>
                      </div>
                    </Flex>
                    <Flex justify={"center"} align="end" style={{ flexGrow: "1" }}>
                      <Button type="primary" disabled={disableButton} loading={spinner1} onClick={() => handleSubscription(lookup_key[0])} style={{ ...buttonStyle, backgroundColor: "#1d1f8c" }}>Buy Now</Button>
                    </Flex>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={6} >
                  <div class="creditBox creditBox2" >
                    <Flex justify={"center"} style={{ height: "6vh", background: "linear-gradient(135deg, rgba(243,181,115,1) 2%, rgba(193,116,38,1) 19%, rgba(193,116,38,1) 84%, rgba(243,181,115,1) 98%)", marginBottom: "20%" }}>
                      {/*Title**/}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ display: "block", marginBottom: "-1px" }}>
                          <Typography><h1 style={{ color: "white" }}>BRONZE</h1></Typography>
                        </span>
                      </div>
                    </Flex>
                    <Flex justify="center" align="center" style={{ height: "12vh" }}>
                      <div className="innerBox" style={{ border: "2px solid rgba(193,116,38,1)"}}>
                        <Row justify="center" align="center" >
                          <h1 style={{ fontSize: "2rem", color: "rgba(193,116,38,1)" }}>{prices[1]}</h1>
                          <text style={{fontSize:"13px", fontWeight:"bold"}}>
                          {userLocation?.country === "GB" ? 
                          "+VAT"
                          : ""}
                          </text>
                        </Row>
                        <Row>
                          <Typography style={{ marginTop: "-10px", justifyContent: "center", textAlign: "center" }}><p>100 Calculations</p> </Typography>
                        </Row>
                      </div>
                    </Flex>

                    <Flex justify={"center"} align="end" style={{ flexGrow: "1" }}>
                        <Button type="primary" disabled={disableButton} loading={spinner2} onClick={() => handleSubscription(lookup_key[1])} style={{ ...buttonStyle, backgroundColor: "rgba(193,116,38,1)" }}>Buy Now</Button>
                    </Flex>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <div class="creditBox creditBox3">
                    <Flex justify={"center"} style={{ height: "6vh", background: "linear-gradient(135deg, #D1D1D1 2%, #AFAFAF 19%, #AFAFAF 84%, #D1D1D1 98%)", marginBottom: "20%" }}>
                      {/*Title**/}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ display: "block", marginBottom: "-1px" }}>
                          <Typography><h1 style={{ color: "white" }}>SILVER</h1></Typography>
                        </span>
                      </div>
                    </Flex>
                    <Flex justify="center" align="center" style={{ height: "12vh" }}>
                      <div className="innerBox" style={{ border: "2px solid #A0A0A0"}}>
                        <Row justify="center" align="center" >
                          <h1 style={{ fontSize: "2rem", color: "grey" }}>{prices[2]}</h1>
                          <text style={{fontSize:"13px", fontWeight:"bold"}}>
                          {userLocation?.country === "GB" ? 
                          "+VAT"
                          : ""}
                          </text>
                        </Row>
                        <Row>
                          <Typography style={{ marginTop: "-10px", justifyContent: "center", textAlign: "center" }}><p>200 Calculations</p> </Typography>
                        </Row>
                      </div>
                    </Flex>

                    <Flex justify={"center"} align="end" style={{ flexGrow: "1" }}>
                      <Button type="primary" disabled={disableButton} loading={spinner3} onClick={() => handleSubscription(lookup_key[2])}  style={{ 
                        ...buttonStyle, 
                        background: "linear-gradient(135deg, #D1D1D1 6%, #A0A0A0 25%, #A0A0A0 84%, #D1D1D1 98%)", 
                        border: "1px solid #8E8E8E",
                        boxShadow: "2px 2px 5px rgba(150,150,150,0.5)"
                      }}>Buy Now</Button>

                    </Flex>
                  </div>
                </Col>
              </>) : (null)}
                <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                  <div class="creditBox creditBox4">
                    <Flex justify={"center"} style={{ height: "6vh", background: "linear-gradient(135deg, #fceabb 2%, #f3a924 19%,#f8b500 84%,#fceabb 98%)", marginBottom: "20%", }}>
                      {/*Title**/}
                      <div style={{ textAlign: "center" }}>
                        <span style={{ display: "block", marginBottom: "-1px" }}>
                          <Typography><h1 style={{ color: "white" }}>GOLD</h1></Typography>
                        </span>
                      </div>
                    </Flex>
                    <Flex justify="center" align="center" style={{ height: "12vh" }}>
                      <div className="innerBox" style={{ border: "2px solid #f3a924 "}}>
                        <Row justify="center" align="center" >
                          <h1 style={{ fontSize: "2rem", color: "#f3a924" }}>{prices[3]}<text style={{fontSize: "1.2rem"}}></text></h1>
                          <text style={{fontSize:"13px", fontWeight:"bold"}}>
                          {userLocation?.country === "GB" ? 
                          "+VAT"
                          : ""}
                          </text>
                        </Row>
                        <Row justify={"center"} style={{margin:"-10px 0px 0px 0px"}}>
                          Billed Annually
                        </Row>
                        <Row>
                          <Typography style={{ whiteSpace: "normal", justifyContent: "center", textAlign: "center" }}><p>Unlimited Calculations</p> </Typography>
                        </Row>
                      </div>
                    </Flex>


                    <Flex justify={"center"} align="end" style={{ flexGrow: "1" }}>
                      {isSubscribed === true ? (
                        <Button onClick={() => handleBilling()} loading={spinner4} type="primary" style={{ ...buttonStyle, backgroundColor: "#f3a924 ", whiteSpace: "normal" }}> Manage Subscription</Button>
                      )
                        : <Button type="primary" loading={spinner4} size="large" onClick={() => handleSubscription2(lookup_key[3])} style={{ ...buttonStyle, backgroundColor: "#f3a924", whiteSpace: "normal" }}>Subscribe Now</Button>
                      }
                    </Flex>
                  </div>
                </Col>
            </>)}
          </Row>
        </Card>
      </Row>

      <Row justify={'center'} style={{ margin: '3% 0 3% 0' }} gutter={[16, 16]}>
        <Card style={{ width: "60vw", display: isFreeTrial !== null ? "none" : "grid" }}>
          <Flex justify="center" align="center" vertical>
            <Title level={2}>Not Ready to Buy? Test Our Free Calculator!</Title>
            <Button size="large" type='primary' onClick={() => navigator(`/mpe-ael-calc/${selectedStandard}/free`)}>Start Free Calculation </Button>
          </Flex>
        </Card>
      </Row>

      {/* <Row justify={'center'}  style={{ margin: '3% 0 3% 0' }}>
          <Card>
            <Row>Sample Debit Card for Testing</Row>
            <Row>Card Number: <strong>4242 4242 4242 4242</strong></Row>
            <Row>Date: Use a valid future date, such as  <strong>12/34</strong></Row>
            <Row>CVC:  <strong> Any three digit number </strong></Row>
          </Card>
      </Row> */}
    </>
  )
}

export default SubscriptionPlan