import React, { useContext, useEffect, useState } from 'react'
import { EnvironmentContext } from '../../../routes/EnvironmentContext'
import { useLocation } from 'react-router-dom'
import ErrorPage from '../../ErrorPages/ErrorPage'

const StandardHandler = ({ children }) => {
    const { selectedStandard } = useContext(EnvironmentContext)
    const [standardPath, setStandardPath] = useState(null)
    const [calcType, setCalcType] = useState(null)
    // This handler will check if webpage is either from approved standard or not.
    const path = useLocation()
    // console.log(`From Standard Helper:`, pathname)
    useEffect(() => {
        const { pathname } = path
        if (pathname) {

            const [_, calcPath, thisStandardPath, thisCalcType] = pathname.split('/')
            setStandardPath(thisStandardPath)
            setCalcType(thisCalcType)
        }
    }, [path])
    return (
        (['iec'].includes(standardPath)) ? (
            <>
                {children}
            </>
        ) : (
            <ErrorPage />
        )
    )
}

export default StandardHandler
