import { Card, Result, Row, Typography } from 'antd'
import React from 'react'
import { FooterHandler } from '../components/Footer/Footer'
import { NavLink, useLocation } from 'react-router-dom'
import ErrorPage from './ErrorPages/ErrorPage'

const ActivationSuccess = () => {
    const location = useLocation()
    const fromConfirm = location?.state?.fromConfirm || false

    // const location = window.location.origin

    console.log(`Activation Success Component is showing:`, location)
    // const isFromConfirmation = location?.state?.iFromConfirmation
    if (fromConfirm) {
        return (
            <>
                <>
                    <Row justify='center' align='middle' style={{ backgroundColor: 'white', height: '100vh' }}>
                        <Card
                            style={{
                                background: 'white',
                                width: 600,
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            }}
                        >
                            <Result
                                status='success'
                                title='Account Activated!'
                                subTitle='You have successfully activated your account.'
                                extra={
                                    <Row style={{ margin: '8px 0' }} justify={'center'}>
                                        <Typography><NavLink to='/'>Click here to login</NavLink></Typography>
                                    </Row>
                                }
                            />
                        </Card>
                    </Row>



                    {/* <Flex justify='center' style={{ width: "100%", minHeight: "100vh" }}>
    
                </Flex> */}
                    <FooterHandler />
                </>
            </>
        )
    } else {
        return <ErrorPage />
    }

}

export default ActivationSuccess