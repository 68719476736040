import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Input, Modal, Divider, Space, Flex, Typography, message, Row, Card } from "antd";
import { NavLink } from "react-router-dom";
import axios from 'axios'
import Cookies from 'js-cookie'
import { EnvironmentContext } from "../../routes/EnvironmentContext";

export function EditProfile(props) {
  const { myUser, setMyUser, isLocal } = useContext(EnvironmentContext)
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm()
  const defaultValue = {
    first_name: myUser?.firstName,
    last_name: myUser?.lastName,
  }
  console.log(`HOY heehe`, 'asdasd', Cookies.get("RTLSS_user"))



  const [isLoading, setIsLoading] = useState(false)

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })
  }

  const showError = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
      duration: 4
    })
  }

  const handleChangeDetails = (e) => {
    setIsLoading(true)

    axios({
      method: 'post',
      url: (() => {
        if (isLocal) {
          return 'http://localhost:8000/change-details'
        } else {
          return 'api/change-details'
        }
      })(),
      data: { ...e, myUser: myUser },
      withCredentials: true
    })
      .then((response) => {

        let message = response?.data.message
        setMyUser({ ...myUser, firstName: form.getFieldValue(['first_name']), lastName: form.getFieldValue(['last_name']) })
        success(message)
      })
      .catch((err) => {
        console.error(`returned by  axios: `, err)
        showError(err.response.data.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {

  }, [])


  return (
    <>
      {contextHolder}

      <Divider orientationMargin="0">
        <h1 style={{ color: "#1d1f8c" }}>Edit Profile</h1>
      </Divider>

      <Card style={{ width: "60%", display: "flex", justifyContent: "center" }}>
        <Form
          form={form}
          onFinish={handleChangeDetails}
          style={{ maxWidth: 600 }}
          layout="vertical"
          autoComplete="off"
          initialValues={defaultValue}>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{
              min: 2,
              message: "First name must be atleast 2 characters"
            }, {
              required: true,
              message: 'Please provide a valid name.'
            }]}>
            <Input placeholder="First Name" />
          </Form.Item>

          <Form.Item label="Last Name" name="last_name"
            rules={[{
              min: 2,
              message: "Last name must be atleast 2 characters"
            }, {
              required: true,
              message: 'Please provide a valid name.'
            }]}>
            <Input placeholder="Last Name" />
          </Form.Item>
          {/* <Divider /> */}
          <Flex justify="center">
            <div>Looking to change your email or password? <NavLink to='/secure-change' onClick={() => { props.setIsModalOpen(false) }}>Click here!</NavLink></div>
          </Flex>
          <Row justify={"end"} style={{ marginTop: "20px" }}>
            <Button loading={isLoading} block htmlType="submit" type='primary' onClick={() => { form.submit() }} style={{ backgroundColor: "#1d1f8c" }}>
              Save Changes
            </Button>
          </Row>
        </Form>
      </Card>
    </>);
}
